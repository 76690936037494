$font-size: 16px !default;
$font-weight: 400 !default;
$heading-weight: 600 !default;
$line-height: 1.65 !default;
$link-color: #68f !default;
$dark: #282828 !default;
$light: #ffffff !default;

$red: #ff3a4c !default;
$green: #00c261 !default;
$blue: #4b6fff !default;

@function reduce ($percent) {
  @return rgba(mix($dark, $light), $percent / 100); }
